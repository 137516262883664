import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import styled from "styled-components"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

import Credito from "../components/infoCards/credito"

const IntermediacaoDeCreditoPage = ({ data }) => (
  <>
    <SEO title="Termos e Política de Privacidade" />
    <Layout>
      {console.log(data.globalJson.politicas.intro.background)}
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} />
          <BgStyled bg={`url("${data.globalJson.politicas.introMobile.background.childImageSharp.fluid.src}")`} />
          <PoliticaPageStyled>
            <Credito />
            <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
          </PoliticaPageStyled>
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} />
          <BgStyled bg={`url("${data.globalJson.politicas.intro.background.childImageSharp.fluid.src}")`}  />
          <PoliticaPageStyled>
            <Credito />
            <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
          </PoliticaPageStyled>
        </>
      )}
    </Layout>
  </>
)

export default IntermediacaoDeCreditoPage


const BgStyled = styled.div`
background-repeat: no-repeat;
background-position: top;
background-size: cover;
background-image:  ${(props) => props.bg || ''};
  left: 0;
  right: 0;
  z-index: -99;
  display: block;
  width: 100%;
  height: 100vh;
  position: fixed;
`

const PoliticaPageStyled = styled.div`
position: absolute;
left: 0;
right: 0;
z-index: 99;
background-color: transparent !important;
`
export const Json = graphql`
  query credito {
    globalJson {
      politicas{
        intro {
          background {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        introMobile {
          background {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

import React from "react"
import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Credito = () => {

  return (
    <StyledCredito>
      <div className="termos">
        <h2 className={font.oL}>INFORMAÇÃO RELATIVA À ATIVIDADE DE INTERMEDIÁRIO DE CRÉDITO</h2>
      </div>
      <div className="politica">
        <ul>
          <li className={font.oR}>1. HCSRP UNIPESSOAL LDA. ESTÁ REGISTADO CATEGORIA DE INTERMEDIÁRIO DE CRÉDITO A TÍTULO ACESSÓRIO NO BANCO DE PORTUGAL SOB O Nº 0004113</li>
          <li className={font.oR}>2. REGISTO DISPONÍVEL PARA CONSULTA NO PORTAL DO CLIENTE BANCÁRIO EM: <a href="HTTPS://WWW.BPORTUGAL.PT/INTERMEDIARIOCREDITOFAR/HCSRP-UNIPESSOAL-LDA" target="_blank">HTTPS://WWW.BPORTUGAL.PT/INTERMEDIARIOCREDITOFAR/HCSRP-UNIPESSOAL-LDA</a></li>
          <li className={font.oR}>3. CONTACTOS:</li>
          <ul className="subitems">
            <li className={font.oR}>3.1 SEDE NA RUA D. JOÃO EVANGELISTA DE LIMA VIDAL Nº259 3810-124 AVEIRO</li>
            <li className={font.oR}>3.2 TELEFONE: 912091667</li>
            <li className={font.oR}>3.3 EMAIL: HCS@HCSPERFORMANCE.PT</li>
          </ul>
          <li className={font.oR}>4.MUTUANTES COM QUEM MANTÊM CONTRATO DE VINCULAÇÃO:
            <br />
            <p className="subitems">
              321 CRÉDITO - INSTITUIÇÃO FINANCEIRA DE CRÉDITO S.A.<br />
              BBVA - INSTITUIÇÃO FINANCEIRA DE CRÉDITO S.A.<br />
              COFIDIS<br />
              BI CREDIT - SOCIEDADE FINANCEIRA DE CRÉDITO S.A.</p></li>
          <li className={font.oR}>5. SERVIÇOS DE INTERMEDIAÇÃO DE CRÉDITO:</li>
          <ul className="subitems">
            <li className={font.oR}>5.1 APRESENTAÇÃO OU PROPOSTA DE CONTRATOS DE CRÉDITO A CONSUMIDORES;</li>
          </ul>
          <li className={font.oR}>6.  CONTRATO DE SEGURO DE RESPONSABILIDADE CIVIL PROFISSIONAL CELEBRADO COM:
            <br />
            <p className="subitems">
            COMPANHIA DE SEGUROS ALLIANZ PORTUGAL S.A. – APÓLICE Nº 205126254 – VÁLIDO DE
            06/12/2023 ATÉ 05/12/2024</p>
          </li>
          <li className={font.oR}>7.  HCSRP UNIPESSOAL LDA. NÃO PODE RECEBER OU ENTREGAR QUAISQUER VALORES
            RELACIONADOS COM A FORMAÇÃO, A EXECUÇÃO E O CUMPRIMENTO ANTECIPADO DOS
            CONTRATOS DE CRÉDITO, NOS TERMOS DO ARTIGO 46º DO DECRETO-LEI 81-C/2017, DE 07/07.</li>
          <li className={font.oR}>8. A ATIVIDADE DE INTERMEDIÁRIO DE CRÉDITO DA HCSRP UNIPESSOAL LDA. ESTÁ SUJEITA À
            SUPERVISÃO DO BANCO DE PORTUGAL.</li>
        </ul>
      </div>
    </StyledCredito>
  )
}

export default Credito

const StyledCredito = styled.div`
background-color: transparent !important;
  position: relative;
  word-wrap: break-word;
  padding: 120px 0 60px;
  .termos, .politica, .comissao{
    margin: auto;
    width: 60%;
    min-width: 300px;
  }
  h2,p{margin: 2em 0;}
  li{margin: 1em 0;}

  .subitems{
    margin-left: 2vw;
  }
`